* {
    padding: 0;
    margin: 0;
}

body {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.7;
    /* es 1.7 más grande que la altura */
    color: black;
    background: rgb(224, 224, 224);
}

.padding {
    padding: 0;
}

.padding2 {
    width: 100%;
    display: inline-flex;
    flex: 1;
    flex-direction: column;
    padding: 0;
}

.espacio {
    padding-top: 40px;
}

.contendorTittle {
    position: absolute;
    display: inline-block;
    top: 5px;
    left: 40%;
}

.Tittle {
    display: inline-block;
    font-family: "Roboto";
    font-size: 25px;
    font-weight: 700px;
    letter-spacing: 1.5px;
    /* margin-top: 40px; */
    /* margin-bottom: 40px; */
    color: #fff;
}

.logo {
    margin: 15px;
    height: 30px;
}


/* .vertical{
    flex-direction: column;
    display: flex;
    text-align: left;
}

select {
    height: 400px !important;
} */

.header {
    height: 60px;
    background: #BB0A21;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}