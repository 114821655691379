*{
    padding: 0;
    margin: 0;
}

body{
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.7; /* es 1.7 más grande que la altura */
    color:black;
    
}


.container-inicio{
    height: 100vh;
    position: relative;
    background-color: #BB0A21;
    clip-path: polygon(0 0, 80% 0%,100% 30%, 100% 100%,0 100%);
    
}

h1{
    font-size: 60px;
    font-weight: 400px;
    letter-spacing: 5px;
    color:#fff;
}
.logo-box{
    position: absolute;
    top:30px;
    left:30px;
    margin:20px;
    
}

.logo{
    height: 40px;
}
 
.box{
    position: absolute;
    top: 35%;
    left: 50%;
    text-align: center;
    transform: translate(-50%,-50%);
}

.btnt{
    margin: 30px;
}



h1{
    font-size: 50px;
    
}


