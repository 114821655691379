.row.fondos {
    background: rgba(187, 184, 184, 0.534);
}

label span{
    text-align: center;
}

/* .selectt{
    margin-top:16px;
} */

.tituloFcAg{
    padding-left: 10px;
    font-family:'Roboto', sans-serif;
    color: rgb(138, 143, 143);
}

.btn-json{
    text-align: center;
    color:white;
    border: 1px solid rgb(240, 45, 71);
    background:#BB0A21;
    padding: 10px 20px;
    font-size: 20px;
    font-family: "Roboto";
    cursor: pointer;
    margin: 40px;
    transition: 0.8s;
    animation: moveInLeft 3s ease-out;
}

.btn-json:hover{

    color:white;
    background:rgb(240, 45, 71);
   


}

/* .selectMultiple{

    height: 500px;
} */



.box1{ /* centro de costo */
    padding-left: 10px;
    margin-top: 20px;
    height: 400px;
    min-width: 80px;
    animation: moveInLeft 3s ease;
}

.box35{
    display: flex;
    flex-direction: column;
    padding-right:15px;
}

.box2{ /*Cotizacion-box*/ 
    
    margin-top: 20px;
    height: 10px;
    min-width: 80px;
    margin-bottom: 10px;
    animation: moveInLeft 3s ease;
}

.box3{/* tipo de moneda */
    
    margin-top: 20px;
    /* margin-bottom: 20px; */
   
    animation: moveInLeft 3s ease;
}



.box4{ /* Seleccion de documento */
    
    /* margin-top: 40px; */
    padding:20px;
    height: 100px;
    min-width: 80px;
    animation: moveInLeft 3s ease;
}


.box5{ /* Plantilla a finnegans */
    margin-top: 20px;
    height: 445px;
    min-width: 80px;
    animation: moveInLeft 3s ease;
   
}

.box4-interno{
    
    height: 443px;
    min-width: 80px;
    animation: moveInLeft 3s ease;
    text-decoration: none;
 
    text-align: center;
    display: flex;
    flex-direction: column;
   
}

.box4-interno a{

    font-family: 'Roboto';
    color:rgb(141, 136, 136);
    text-decoration: none;
    animation: 4s ease;
    
}

.box4-interno a:hover{
    color:rgb(61, 53, 53);
    text-decoration: none;
    
    
   
}

label{
    padding-left: 10px;
}

.insede-box{ /* box inside cotización */
    height: 130px;
}

.inside-formcontro1{
    padding-top:15px;
    padding-left: 45px;
}

.box2 .insede-box .style-label{
    margin-bottom:10px
}

.box5 .style-label{
    margin-bottom:10px
}

label{
    color:rgb(141, 136, 136);
    font-family: 'Roboto';

}

.warning-style{
    /* width: 80px;  */
    background:white;
    border-radius: 10px;
    margin-top: 100px;
    /* height: 50px; */
    text-align: center;

}

.tablaNew{
    height: 240px;
}



@keyframes moveInLeft{

    0%{
        opacity: 0;
    }

    100%{
        opacity: 1;
    }
}


