.boxOpacity2{ /*Cotizacion-box*/ 
    
    margin-top: 20px;
    height: 10px;
    min-width: 80px;
    margin-bottom: 10px;
    opacity: 0;
    /* animation: moveInLeft 3s ease; */
}

.boxOpacity5{/* tipo de moneda */
    
    margin-top: 150px;
    /* margin-bottom: 20px; */
    height: 90px;
    min-width: 80px;
    opacity: 0;
    /* animation: moveInLeft 3s ease; */
}



.boxOpacity3{ /* Seleccion de documento */
    
    margin-top: 20px;
    text-align: center;
    opacity: 0;
    /* animation: moveInLeft 3s ease; */
}


.boxOpacity4{ /* Plantilla a finnegans */
    
    margin:20px;
    height: 100px;
    min-width: 80px;
    opacity: 0;
    /* animation: moveInLeft 3s ease; */
   
}

@keyframes moveInLeft{

    0%{
        opacity: 0;
    }

    100%{
        opacity: 1;
    }
}