.ScrollTble{

    overflow:scroll;
    height: 500px;
}

th{
    /* width: 15px !important; */
}

.linkSin{
    text-decoration: none ;
    color:rgba(64, 6, 255, 0.87)
}

.linkSin:hover{
    text-decoration: none ;
    color:rgba(0, 7, 110, 0.87);
    text-decoration: underline;
}

.izquierda{
    text-align: right;
    margin-right: 20px;
    margin-top: 1px;
}

.izquierda:hover{
    cursor: pointer;
}

